import apiService from "../../utils/apiService";
import { CLEAR_JWT, SET_JWT, SET_MULTIPLE } from "./types";
import i18n from "../../localization/i18n";

export const login = (email, password) => async (dispatch) => {
  try {
    const { data } = await apiService.post("/user/signin", {
      email,
      password,
    });
    dispatch({
      type: SET_JWT,
      payload: data.jwt,
    });
    return { result: true };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.wrong_creds"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_JWT });
};

export const inviteGuest = async (phone) => {
  try {
    const { data } = await apiService.post("/client", {
      phone,
      confirmed: true,
    });
    return {
      result: true,
      id: data.id,
    };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.card_already_exist"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const getGuestDataById = async (guestId) => {
  try {
    const { data } = await apiService.get(`/client/${guestId}`);
    return data;
  } catch (e) {}
};

export const getGuestDataByPhone = async (phone) => {
  try {
    const { data } = await apiService.get(`/client/phone/${phone}`);
    return {
      result: true,
      fields: data,
    };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 404:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.unknown_card"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const getChainParams = async () => {
  try {
    const { data } = await apiService.get("/chain");
    return data;
  } catch (e) {}
};

export const addStamps = async (phone, count) => {
  try {
    await apiService.post(`/client/addstamps`, {
      phone,
      count: !!count ? count : 1,
    });
    return {
      result: true,
    };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.accrue_error"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const giveGift = async (phone) => {
  try {
    await apiService.post("/client/givegift", { phone });
    return { result: true };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.accrue_error"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const checkPaymentStatus = async () => {
  try {
    await apiService.get("/user/checkAccess");
    return { result: true };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 402:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.need_payment"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const resetPassword = async (email) => {
  try {
    await apiService.post("/user/reset", { email });
    return { result: true };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response.status) {
        case 400:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.unknown_email"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const getLocation = async () => {
  try {
    const { data } = await apiService.get("/location");
    return {
      result: true,
      id: data[0]._id,
    };
  } catch (e) {
    return { result: false, msg: i18n.t("core.actions_msgs.server_error") };
  }
};

export const checkTemplate = async () => {
  try {
    await apiService.get(`/card/template`);
    return { result: true };
  } catch (e) {
    if (e.isAxiosError) {
      switch (e.response?.status) {
        case 404:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.template_not_exist"),
          };
        default:
          return {
            result: false,
            msg: i18n.t("core.actions_msgs.server_error"),
          };
      }
    }
  }
};

export const checkMultiple = () => async (dispatch) => {
  try {
    const { data } = await apiService.get("/chain");
    dispatch({ type: SET_MULTIPLE, payload: !!data.multipleStamps });
  } catch (e) {}
};
