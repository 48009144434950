import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConfiguration } from "react-grid-system";
import {
  checkMultiple,
  checkPaymentStatus,
  checkTemplate,
  getChainParams,
} from "../store/app/actions";
import { setModalContent, setModalVisible } from "../store/modal/actions";
import ErrorBanner from "../views/modal-views/ErrorBanner";
import { useTranslation } from "react-i18next";
import getUserLanguage from "../utils/getUserLanguage";
import i18n from "../localization/i18n";

function useOnAuth() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthorized = useSelector(({ app }) => !!app.jwt);
  setConfiguration({ maxScreenClass: "sm" });

  useEffect(() => {
    const processPaywall = async () => {
      const { result, msg } = await checkPaymentStatus();
      if (!result) {
        dispatch(setModalVisible(true));
        dispatch(
          setModalContent(
            <ErrorBanner
              msg={msg}
              title={t("views.popovers.need_payment_title")}
              haveLogout
            />
          )
        );
      }
    };

    const isTemplateExist = async () => {
      const { result, msg } = await checkTemplate();
      if (!result) {
        dispatch(setModalVisible(true));
        dispatch(
          setModalContent(
            <ErrorBanner
              msg={msg}
              title={t("views.popovers.error_title")}
              haveLogout
            />
          )
        );
      }
    };

    const setAppLanguage = async () => {
      const { language } = await getChainParams();
      await i18n.changeLanguage(language);
    };

    if (isAuthorized) {
      setAppLanguage();
      processPaywall();
      isTemplateExist();
      dispatch(checkMultiple());
    } else {
      i18n.changeLanguage(getUserLanguage()[0]);
    }
  }, [isAuthorized]);
}

export default useOnAuth;
