import {
  CLEAR_NAVBAR,
  SET_CLOSE,
  SET_EXIT,
  SET_GO_BACK,
  SET_NAVBAR_TEXT,
} from "./types";

export const setTitleText = (text) => (dispatch) =>
  dispatch({
    type: SET_NAVBAR_TEXT,
    payload: text,
  });

export const setGoBack = (action) => (dispatch) => {
  dispatch({
    type: SET_GO_BACK,
    payload: action,
  });
};

export const setExit = (action) => (dispatch) => {
  dispatch({
    type: SET_EXIT,
    payload: action,
  });
};

export const setClose = (action) => (dispatch) => {
  dispatch({
    type: SET_CLOSE,
    payload: action,
  });
};

export const clearNavbar = () => (dispatch) => {
  dispatch({
    type: CLEAR_NAVBAR,
  });
};
