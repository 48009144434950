import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import appReducer from "./app/reducer";
import navBarReducer from "./navbar/reducer";
import thunk from "redux-thunk";
import modalReducer from "./modal/reducer";

const store = createStore(
  combineReducers({
    app: appReducer,
    navbar: navBarReducer,
    modal: modalReducer,
  }),
  compose(applyMiddleware(thunk))
);

export default store;
