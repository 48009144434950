import styled from "styled-components";

const Error = styled.div`
  display: ${({ children }) => (!!children ? "flex" : "none")};
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 10px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.input.error.border};
`;

export default Error;
