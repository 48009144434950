import produce from 'immer';
import {
  CLEAR_NAVBAR,
  SET_CLOSE,
  SET_EXIT,
  SET_GO_BACK,
  SET_NAVBAR_TEXT,
} from "./types";

const initialState = {
  text: "",
  leftIcon: "",
  rightIcon: "",
  leftOnClick: () => {},
  rightOnClick: () => {},
};
export default function navBarReducer(state = initialState, action) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case SET_NAVBAR_TEXT:
        draft.text = payload;

        return draft;
      case CLEAR_NAVBAR:
        draft.leftIcon = "";
        draft.rightIcon = "";

        return draft;
      case SET_GO_BACK:
        draft.leftIcon = "arrow";
        draft.rightIcon = "";
        draft.leftOnClick = payload;

        return draft;
      case SET_CLOSE:
        draft.leftIcon = "";
        draft.rightIcon = "cross";
        draft.rightOnClick = payload;

        return draft;
      case SET_EXIT:
        draft.leftIcon = "";
        draft.rightIcon = "exit";
        draft.rightOnClick = payload;

        return draft;
      default:
        return draft;
    }
  })
}
