import axios from "axios";
import store from "../store";

const apiService = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_TYPE === "PROD"
        ? "https://v2.api.6tamp.ru"
        : "https://v2.api.lip1.ru"
      : // : "http://localhost:8080",
        "https://v2.api.lip1.ru",
});

apiService.interceptors.request.use((requestConfig) => {
  const { jwt } = store.getState().app;
  if (jwt) {
    requestConfig.headers = {
      Authorization: `Bearer ${jwt}`,
    };
  }
  return requestConfig;
}, null);

export default apiService;
