import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import {
  MobilePageHeader,
  MobilePageDescription,
  TextInput,
  Button,
  Error,
} from "../../components";
import { useDispatch } from "react-redux";
import { login } from "../../store/app/actions";
import { clearNavbar } from "../../store/navbar/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isError } from "../../utils";
import { useTranslation } from "react-i18next";

export default function SignIn() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearNavbar());
  }, [dispatch]);

  const [loginResult, setLoginResult] = useState({});

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("core.validation.email.invalid"))
        .required(t("core.validation.required")),
      password: Yup.string()
        .min(8, t("core.validation.password.short"))
        .max(20, t("core.validation.password.long"))
        .required(t("core.validation.required")),
    }),
    onSubmit: async (values) => {
      const { result, msg } = await dispatch(
        login(values.email, values.password)
      );
      setLoginResult({ result, msg });
    },
  });

  const onFormChange = (e) => {
    setLoginResult({});
    formik.handleChange(e);
  };

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} onChange={onFormChange}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>{t("views.sign_in.header")}</MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.sign_in.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TextInput
              placeholder={t("core.input_defs.email.label")}
              name={"email"}
              error={isError(formik, "email") ? formik.errors.email : ""}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TextInput
              placeholder={t("core.input_defs.password.label")}
              name={"password"}
              type={"password"}
              error={isError(formik, "password") ? formik.errors.password : ""}
            />
          </Col>
        </Row>
        {!loginResult.result && <Error>{loginResult.msg}</Error>}
        <Row>
          <Col xs={12}>
            <Button display={"block"} disabled={!formik.dirty} type={"submit"}>
              {t("views.sign_in.btn_sign_in")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              role={"flat"}
              tag={"link"}
              href={"/restore"}
              display={"block"}
            >
              {t("views.sign_in.btn_restore")}
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
