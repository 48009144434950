import React from "react";
import * as Styled from "./style";
import { Select, TextInput } from "../index";

const PhoneInput = ({
  codeItems,
  maskWithoutCode,
  code,
  setCode,
  setValue,
  placeholder,
  error,
}) => {
  return (
    <Styled.PhoneInput>
      <Select
        items={codeItems}
        value={codeItems.find((el) => el.code === code)}
        setValue={(obj) => setCode(obj.code)}
      />
      <TextInput
        name={"phone"}
        error={error}
        placeholder={placeholder}
        mask={maskWithoutCode}
        onChange={(e) => setValue(e.target.value)}
      />
    </Styled.PhoneInput>
  );
};

export default PhoneInput;
