import {
  MobilePageHeader,
  MobileModalContent,
  Button,
  MobilePageDescription,
} from "../../../components";
import { Container, Row, Col } from "react-grid-system";
import React from "react";
import { setModalContent, setModalVisible } from "../../../store/modal/actions";
import {
  addStamps,
  getGuestDataByPhone,
  giveGift,
} from "../../../store/app/actions";
import { useDispatch, useSelector } from "react-redux";
import AfterGiveGift from "../AfterGiveGift";
import Error from "../Error";
import { CountOfStamps } from "../CountOfStamps";
import ScanResultUpdate from "../ScanResultUpdate";
import { useTranslation } from "react-i18next";

export default function GiftScanSelector({ phone }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const multipleStamps = useSelector(({ app }) => app.multipleStamps);

  const onGiftBtnClick = async () => {
    const { result } = await giveGift(phone);
    if (result) {
      dispatch(setModalContent(<AfterGiveGift />));
    } else {
      dispatch(
        setModalContent(
          <Error
            title={t("views.popovers.gift_error.title")}
            desc={t("views.popovers.gift_error.desc")}
          />
        )
      );
    }
  };

  const onStampBtnClick = async () => {
    const { result, fields, msg } = await getGuestDataByPhone(phone);
    if (result) {
      if (multipleStamps) {
        dispatch(
          setModalContent(
            <CountOfStamps
              curStamps={fields?.curStamps}
              phone={fields?.phone}
            />
          )
        );
      } else {
        const { result } = await addStamps(phone);
        if (result) {
          dispatch(setModalContent(<ScanResultUpdate />));
        } else {
          dispatch(
            setModalContent(
              <Error
                title={t("views.popovers.server_error.title")}
                desc={t("views.popovers.server_error.desc")}
                backUrl={"/"}
                backBtnText={t("views.popovers.server_error.btn_back")}
              />
            )
          );
        }
      }
    } else {
      dispatch(setModalVisible(true));
      dispatch(
        setModalContent(
          <Error
            title={t("views.popovers.error_title")}
            desc={msg}
            backUrl={"/scan"}
            backBtnText={t("views.popovers.btn_try_again")}
          />
        )
      );
    }
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>
              {t("views.modals.gift_scan_selector.header")}
            </MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.modals.gift_scan_selector.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              role={"primary"}
              display={"block"}
              onClick={onStampBtnClick}
            >
              {t("views.modals.gift_scan_selector.btn_accrue_stamp")}
            </Button>
            <Button
              role={"secondary"}
              display={"block"}
              onClick={onGiftBtnClick}
            >
              {t("views.modals.gift_scan_selector.btn_give_gift")}
            </Button>
          </Col>
        </Row>
      </MobileModalContent>
    </Container>
  );
}
