import {
  MobilePageHeader,
  MobilePageDescription,
  MobileModalContent,
  Button,
} from "../../../components";
import { Container, Row, Col } from "react-grid-system";
import React from "react";
import { setModalContent, setModalVisible } from "../../../store/modal/actions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function AfterRestore() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const goToMain = () => {
    dispatch(setModalVisible(false));
    dispatch(setModalContent(null));
    history.push("/");
  };
  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>
              {t("views.modals.after_restore.header")}
            </MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.modals.after_restore.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button display={"block"} role={"primary"} onClick={goToMain}>
              {t("views.modals.after_restore.btn_goto")}
            </Button>
          </Col>
        </Row>
      </MobileModalContent>
    </Container>
  );
}
