import {
  MobilePageHeader,
  MobileModalContent,
  Button,
} from "../../../components";
import { Container, Row, Col } from "react-grid-system";
import React from "react";
import { setModalContent, setModalVisible } from "../../../store/modal/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AfterGiveGift() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const goToMain = () => {
    dispatch(setModalVisible(false));
    dispatch(setModalContent(null));
    history.push("/");
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>
              {t("views.modals.after_gift.header")}
            </MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button role={"secondary"} display={"block"} onClick={goToMain}>
              {t("views.modals.after_gift.btn_goto")}
            </Button>
          </Col>
        </Row>
      </MobileModalContent>
    </Container>
  );
}
