import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Styled from './styled';

const CodeScanner = ({ uniqueId, onSuccess, onError, qrbox, width }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const scanner = new window.Html5Qrcode(uniqueId, false);
    scanner
      .start(
        { facingMode: "environment" },
        { fps: 3, qrbox },
        onSuccess,
        onError
      )
      .catch(() => console.log("ошибка!"));

    return () =>
      scanner
        .stop()
        .catch((err) => console.log("Error during CodeScanner unmount. ", err));
  }, [uniqueId, onError, onSuccess, qrbox, dispatch, history]);

  return (
    <Styled.CodeScanner
      id={uniqueId}
      width={width}
    />
  );
};

export default CodeScanner;

CodeScanner.propTypes = {
  uniqueId: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  qrbox: PropTypes.number,
  width: PropTypes.number,
};
