import styled from "styled-components";
import PropTypes from "prop-types";
export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;

  ~ div {
    display: ${({ visible }) => (visible ? "none" : "flex")};
  }
`;

Wrapper.defaultProps = {
  visible: false,
};

Wrapper.propTypes = {
  visible: PropTypes.bool,
};
