import styled from "styled-components";

export const PhoneInput = styled.div`
  width: 100%;
  height: auto;

  display: grid;
  grid-template-columns: 1.6fr 5fr;
  gap: 0 10px;
`;
