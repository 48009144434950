import { Col, Container, Row } from "react-grid-system";
import {
  Button,
  MobileModalContent,
  MobilePageDescription,
  MobilePageHeader,
} from "../../../components";
import React from "react";
import { setModalContent, setModalVisible } from "../../../store/modal/actions";
import { giveGift } from "../../../store/app/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AfterGiveGift from "../AfterGiveGift";
import Error from "../Error";
import { useTranslation } from "react-i18next";

export default function GiftAvailable({ phone }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const goToMain = () => {
    dispatch(setModalVisible(false));
    dispatch(setModalContent(null));
    history.push("/");
  };

  const onSubmit = async () => {
    const { result } = await giveGift(phone);
    if (result) {
      dispatch(setModalContent(<AfterGiveGift />));
    } else {
      dispatch(
        setModalContent(
          <Error
            title={t("views.popovers.gift_error.title")}
            desc={t("views.popovers.gift_error.desc")}
          />
        )
      );
    }
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>
              {t("views.modals.gift_available.header")}
            </MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.modals.gift_available.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button role={"primary"} display={"block"} onClick={onSubmit}>
              {t("views.modals.gift_available.btn_give_gift")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button role={"secondary"} display={"block"} onClick={goToMain}>
              {t("views.modals.gift_available.btn_goto")}
            </Button>
          </Col>
        </Row>
      </MobileModalContent>
    </Container>
  );
}
