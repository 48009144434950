import {
  MobilePageHeader,
  MobilePageDescription,
  TextInput,
  Button,
  Error,
} from "../../components";
import { Container, Row, Col } from "react-grid-system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setModalContent, setModalVisible } from "../../store/modal/actions";
import { setGoBack } from "../../store/navbar/actions";
import { resetPassword } from "../../store/app/actions";
import { useHistory } from "react-router-dom";
import AfterRestore from "../modal-views/AfterRestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isError } from "../../utils";
import { useTranslation } from "react-i18next";

export default function Restore() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [resetResult, setResetResult] = useState({});

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("core.validation.email.invalid"))
        .required(t("core.validation.required")),
    }),
    onSubmit: async (values) => {
      const { result, msg } = await resetPassword(values.email);
      setResetResult({ result, msg });
      if (result) {
        dispatch(setModalVisible(true));
        dispatch(setModalContent(<AfterRestore />));
      }
    },
  });

  useEffect(() => {
    dispatch(setGoBack(() => history.push("/")));
  }, [dispatch, history]);

  const onFormChange = (e) => {
    setResetResult({});
    formik.handleChange(e);
  };

  return (
    <Container>
      <form onChange={onFormChange} onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>{t("views.restore.header")}</MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.restore.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <TextInput
              placeholder={t("core.input_defs.email.label")}
              name={"email"}
              error={isError(formik, "email") ? formik.errors.email : ""}
            />
          </Col>
        </Row>
        {!resetResult.result && <Error>{resetResult.msg}</Error>}
        <Row>
          <Col xs={12}>
            <Button display={"block"} disabled={!formik.dirty} type={"submit"}>
              {t("views.restore.btn_send_new_password")}
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
