import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 15px 0;

  input:checked + label {
    background-color: ${({ theme }) => theme.purple};
    border: 1px solid ${({ theme }) => theme.buttons.primary.border};
    color: ${({ theme }) => theme.buttons.primary.border};
  }

  input {
    display: none;
  }
`;

export const SelectInput = styled.label`
  display: flex;

  width: 100%;
  height: 70px;

  border: 1px solid ${({ theme }) => theme.input.normal.border};

  border-radius: 8px;

  span {
    margin: auto;

    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }
`;
