import styled from "styled-components";

const PageDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondaryText};
  margin-bottom: 40px;
`;

export default PageDescription;
