import {
  MobilePageHeader,
  MobilePageDescription,
  Button,
  Error,
  PhoneInput,
} from "../../components";
import { Container, Row, Col } from "react-grid-system";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { inviteGuest } from "../../store/app/actions";
import { setModalContent, setModalVisible } from "../../store/modal/actions";
import { setGoBack } from "../../store/navbar/actions";
import { useHistory } from "react-router-dom";
import AfterInvite from "../modal-views/AfterInvite";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isError } from "../../utils";
import { useTranslation } from "react-i18next";
import i18n, { countryPhoneDictionary } from "../../localization/i18n";
import {
  getMaskLengthByLang,
  getPhoneCodeByLang,
  getMaskByCode,
} from "../../utils/phone";

const codeItems = Object.entries(countryPhoneDictionary).map(
  ([key, obj], i) => ({ id: i, tag: key, code: obj.code })
);

export default function GuestInvite() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const validRef = useRef(getMaskLengthByLang(i18n.language));

  const [inviteResult, setInviteResult] = useState({});

  const formik = useFormik({
    initialValues: {
      code: getPhoneCodeByLang(i18n.language),
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .length(validRef.current, t("core.validation.phone.invalid"))
        .required(t("core.validation.required")),
    }),
    onSubmit: async (values) => {
      const { result, id, msg } = await inviteGuest(
        `${values.code} ${values.phone}`.replace(/\D/g, "")
      );
      setInviteResult({ result, msg });
      if (result) {
        dispatch(setModalVisible(true));
        dispatch(setModalContent(<AfterInvite clientId={id} />));
      }
    },
  });

  useEffect(() => {
    formik.setFieldError("phone", undefined);
    const newValidLength = Object.values(countryPhoneDictionary).find(
      (el) => el.code === formik.values.code
    ).mask.length;
    validRef.current = newValidLength;
    formik.setFieldValue("phone", formik.values.phone.slice(0, newValidLength));
  }, [formik.values.code]);

  useEffect(() => {
    dispatch(
      setGoBack(() => {
        history.push("/");
      })
    );
  }, [dispatch, history]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} onChange={() => setInviteResult({})}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>
              {t("views.guest_invite.header")}
            </MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.guest_invite.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PhoneInput
              name={"phone"}
              code={formik.values.code}
              setCode={(v) => formik.setFieldValue("code", v, false)}
              value={formik.values.phone}
              setValue={(v) => formik.setFieldValue("phone", v, true)}
              codeItems={codeItems}
              placeholder={t("core.input_defs.phone.label")}
              error={isError(formik, "phone") ? formik.errors.phone : ""}
              maskWithoutCode={getMaskByCode(formik.values.code)}
            />
          </Col>
        </Row>
        <Error>{!inviteResult.result && inviteResult.msg}</Error>
        <Row>
          <Col xs={12}>
            <Button display={"block"} disabled={!formik.dirty} type={"submit"}>
              {t("views.guest_invite.btn_invite")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              display={"block"}
              role={"flat"}
              type={"button"}
              tag={"link"}
              href={"/guest/qr"}
            >
              {t("views.guest_invite.btn_goto_qr")}
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}
