import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-grid-system";
import {
  MobilePageHeader,
  MobilePageDescription,
  Button,
  MobileModalContent,
} from "../../components";
import QRCode from "qrcode.react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setModalContent, setModalVisible } from "../../store/modal/actions";
import { setGoBack } from "../../store/navbar/actions";
import { getLocation } from "../../store/app/actions";
import Error from "../modal-views/Error";
import apiService from "../../utils/apiService";
import { useTranslation } from "react-i18next";

export default function RegistrationQR() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [regUrl, setRegUrl] = useState("");
  useEffect(() => {
    dispatch(
      setGoBack(() => {
        history.push("/guest/invite");
      })
    );
  }, [dispatch, history]);

  useEffect(() => {
    const getLocationId = async () => {
      const { result, id, msg } = await getLocation();
      if (result) {
        const { baseURL } = apiService.defaults;
        if (baseURL.includes("api.6tamp.ru")) {
          setRegUrl(`https://reg.6tamp.ru/?id=${id}`);
        } else {
          setRegUrl(`https://reg.lip1.ru/?id=${id}`);
        }
      } else {
        setModalVisible(true);
        setModalContent(
          <Error title={t("views.popovers.server_error_title")} desc={msg} />
        );
      }
    };
    getLocationId();
  }, []);

  console.log(regUrl);

  return (
    <Container>
      <Row>
        <Col s={12}>
          <MobilePageHeader>{t("views.reg_qr.header")}</MobilePageHeader>
        </Col>
      </Row>
      <Row>
        <Col s={12}>
          <MobilePageDescription>
            {t("views.reg_qr.desc")}
          </MobilePageDescription>
        </Col>
      </Row>
      <Row>
        <Col s={12}>
          <MobileModalContent height={"350px"}>
            <QRCode size={256} value={regUrl} />
          </MobileModalContent>
        </Col>
      </Row>
      <Row>
        <Col s={12}>
          <Button role={"secondary"} display={"block"} tag={"link"} href={"/"}>
            {t("views.reg_qr.btn_goto")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
