const theme = {
  buttons: {
    secondary: {
      text: "#535353",
      background: "#FFFFFF",
      border: "#535353",
    },
    secondary_inverse: {
      text: "#FFFFFF",
      background: "#535353",
      border: "#FFFFFF",
    },
    secondary_disabled: {
      text: "#FFFFFF",
      background: "#D2D2D2",
      border: "#D2D2D2",
    },
    primary: {
      text: "#FFFFFF",
      background: "#A342EF",
      border: "#A342EF",
    },
    primary_inverse: {
      text: "#A342EF",
      background: "#FFFFFF",
      border: "#A342EF",
    },
    primary_disabled: {
      text: "#FFFFFF",
      background: "#D2D2D2",
      border: "#D2D2D2",
    },
    flat: {
      text: "#535353",
      background: "#ffffff",
      border: "#ffffff",
    },
    flat_inverse: {
      text: "#535353",
      background: "#ffffff",
      border: "#ffffff",
    },

    flat_disabled: {
      text: "#898989",
      background: "#ffffff",
      border: "#ffffff",
    },
  },

  input: {
    normal: {
      text: "#535353",
      border: "#B4B4B4",
      placeholder: "#979797",
    },
    error: {
      text: "#535353",
      border: "#D80202",
      placeholder: "#979797",
    },
  },

  mainText: "#282828",
  secondaryText: "#535353",
  purple: "#EDD9FC",
};

export default theme;
