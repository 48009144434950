import React from "react";
import { useSelector } from "react-redux";
import { Wrapper, LeftButton, RightButton } from "./styled";

const NavBar = () => {
  const { leftIcon, rightIcon, leftOnClick, rightOnClick } = useSelector(
    ({ navbar }) => navbar
  );
  return (
    <Wrapper>
      <LeftButton icon={leftIcon} onClick={leftOnClick} />
      <RightButton icon={rightIcon} onClick={rightOnClick} />
    </Wrapper>
  );
};
export default NavBar;
