import styled from "styled-components";

const Input = styled.input`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: ${({ theme, error }) =>
    !!error ? theme.input.error.text : theme.input.normal.text};
  padding: 14px 10px;
  border: 1px solid
    ${({ theme, error }) =>
      !!error ? theme.input.error.border : theme.input.normal.border};
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: ${(state) => (!!state.error ? "0px" : "30px")};
  outline: none;

  ::placeholder {
    color: ${({ theme, error }) =>
      !!error ? theme.input.error.placeholder : theme.input.normal.placeholder};
  }
`;

const Container = styled.div`
  ::after {
    display: ${(state) => (!!state.error ? "block" : "none")};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    content: "${(state) => state.error}";
    margin-top: 10px;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.input.error.border};
  }
`;

export { Container, Input };
