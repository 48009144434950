import {
  MobilePageHeader,
  MobileModalContent,
  Button,
  MobilePageDescription,
} from "../../../components";
import { Container, Row, Col } from "react-grid-system";
import React from "react";
import { setModalContent, setModalVisible } from "../../../store/modal/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export default function Error({
  title,
  desc,
  backUrl = "/",
  backBtnText = "На главную",
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const goToMain = () => {
    dispatch(setModalVisible(false));
    dispatch(setModalContent(null));
    history.push(backUrl);
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>{title}</MobilePageHeader>
          </Col>
        </Row>
        {desc && (
          <Row>
            <Col xs={12}>
              <MobilePageDescription>{desc}</MobilePageDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            <Button role={"secondary"} display={"block"} onClick={goToMain}>
              {backBtnText}
            </Button>
          </Col>
        </Row>
      </MobileModalContent>
    </Container>
  );
}
