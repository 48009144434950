import { Container, Row, Col } from "react-grid-system";
import {
  MobilePageHeader,
  MobilePageDescription,
  CodeScanner,
  Button,
} from "../../components";
import React, { useCallback, useEffect, useState } from "react";
import isMobilePhone from "validator/lib/isMobilePhone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setModalContent, setModalVisible } from "../../store/modal/actions";
import {
  addStamps,
  getChainParams,
  getGuestDataByPhone,
} from "../../store/app/actions";
import { setGoBack } from "../../store/navbar/actions";
import ScanResultUpdate from "../modal-views/ScanResultUpdate";
import GiftAvailable from "../modal-views/GiftAvailable";
import GiftScanSelector from "../modal-views/GiftScanSelector";
import Error from "../modal-views/Error";
import { CountOfStamps } from "../modal-views/CountOfStamps";
import { useTranslation } from "react-i18next";

export default function Scanner() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      setGoBack(() => {
        history.push("/");
      })
    );
  }, [dispatch, history]);

  const multipleStamps = useSelector(({ app }) => app.multipleStamps);

  const [scanResult, setScanResult] = useState("");
  const onScanGetResult = useCallback(
    (value) =>
      !scanResult && isMobilePhone(value) ? setScanResult(value) : false,
    [scanResult]
  );

  const proccessScanResult = useCallback(
    async (value) => {
      const { result, fields, msg } = await getGuestDataByPhone(value);
      if (result) {
        const { curStamps, curGifts, phone } = fields;
        if (!curGifts) {
          // Включена ли опция нескольких штампов у сети. Если включена - показываем модалку с выбором кол-ва штампов
          if (multipleStamps) {
            dispatch(setModalVisible(true));
            dispatch(
              setModalContent(
                <CountOfStamps curStamps={curStamps} phone={phone} />
              )
            );
          } else {
            const { result } = await addStamps(phone);
            if (result) {
              const { maxStamps } = await getChainParams();
              //Если начисленный штамп был недостающим до подарка - спрашиваем, не желает ли гость сразу получить подарок
              dispatch(setModalVisible(true));
              if (curStamps + 1 === maxStamps) {
                dispatch(setModalContent(<GiftAvailable phone={phone} />));
              } else {
                dispatch(setModalContent(<ScanResultUpdate />));
              }
            } else {
              dispatch(
                setModalContent(
                  <Error
                    title={t("views.popovers.server_error.title")}
                    desc={t("views.popovers.server_error.desc")}
                    backUrl={"/"}
                    backBtnText={t("views.popovers.server_error.btn_back")}
                  />
                )
              );
            }
          }
        } else {
          dispatch(setModalVisible(true));
          dispatch(setModalContent(<GiftScanSelector phone={phone} />));
        }
      } else {
        setScanResult("");
        dispatch(setModalVisible(true));
        dispatch(
          setModalContent(
            <Error
              title={t("views.popovers.error_title")}
              desc={msg}
              backUrl={"/scan"}
              backBtnText={t("views.popovers.btn_try_again")}
            />
          )
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (scanResult) {
      proccessScanResult(scanResult);
    }
  }, [scanResult, proccessScanResult]);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <MobilePageHeader>{t("views.scanner.header")}</MobilePageHeader>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <MobilePageDescription>
            {t("views.scanner.desc")}
          </MobilePageDescription>
        </Col>
      </Row>
      <Row>
        <Col xs={1} />
        <Col xs={10}>
          <CodeScanner
            width={250}
            qrbox={100}
            uniqueId={"scanner"}
            onSuccess={onScanGetResult}
          />
        </Col>
        <Col xs={1} />
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            role={"flat"}
            display={"block"}
            tag={"link"}
            href={"/scan/phone"}
          >
            {t("views.scanner.btn_enter_phone")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
