import React, { useState } from "react";
import * as Styled from "./style";

const SelectButton = ({ label, onClick, isDropDownVisible }) => (
  <Styled.SelectButton onClick={onClick}>
    <Styled.SelectButtonInner>
      <span>{label}</span>
      <Styled.Arrow turn_down={isDropDownVisible} />
    </Styled.SelectButtonInner>
  </Styled.SelectButton>
);

const SelectItem = ({ label, isActive, onClick }) => (
  <Styled.SelectItem is_active={isActive} onClick={onClick}>
    <span>{label}</span>
  </Styled.SelectItem>
);

const Select = ({ items, value, setValue }) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState();

  return (
    <Styled.Select>
      <SelectButton
        label={`${value.tag.toUpperCase()} ${value.code}`}
        onClick={() => setIsDropDownVisible((v) => !v)}
        isDropDownVisible={isDropDownVisible}
      />
      {isDropDownVisible && (
        <Styled.SelectItemsContainer>
          {items.map((v) => (
            <SelectItem
              key={v.id}
              label={`${v.tag.toUpperCase()} ${v.code}`}
              onClick={() => {
                setValue(v);
                setIsDropDownVisible(false);
              }}
              isActive={value.id === v.id}
            />
          ))}
        </Styled.SelectItemsContainer>
      )}
    </Styled.Select>
  );
};

export default Select;
