import { countryPhoneDictionary, fallbackLng } from "../localization/i18n";

function getPhoneDataByLang(lang) {
  return countryPhoneDictionary[lang] ?? countryPhoneDictionary[fallbackLng];
}

function getMaskLengthByLang(lang) {
  return getPhoneDataByLang(lang).mask.length;
}

function getPhoneCodeByLang(lang) {
  return getPhoneDataByLang(lang).code;
}

function getMaskByCode(code) {
  return (
    Object.values(countryPhoneDictionary).find((obj) => obj.code === code) ??
    countryPhoneDictionary[fallbackLng]
  ).mask;
}

export { getMaskLengthByLang, getPhoneCodeByLang, getMaskByCode };
