import {
  MobilePageHeader,
  MobileModalContent,
  MobilePageDescription,
  Button,
} from "../../../components";
import { Container, Row, Col } from "react-grid-system";
import React from "react";
import { logout } from "../../../store/app/actions";
import { useDispatch } from "react-redux";

export default function ErrorBanner({ msg, title, haveLogout }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(logout());
    window.location.reload();
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>{title}</MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>{msg}</MobilePageDescription>
          </Col>
        </Row>
        {haveLogout && (
          <Row>
            <Col xs={12}>
              <Button
                role={"secondary"}
                display={"block"}
                onClick={handleClick}
              >
                Выйти из учетной записи
              </Button>
            </Col>
          </Row>
        )}
      </MobileModalContent>
    </Container>
  );
}
