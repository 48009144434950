import React, { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  Button,
  MobileModalContent,
  MobilePageDescription,
  MobilePageHeader,
} from "../../../components";
import { addStamps, getChainParams } from "../../../store/app/actions";
import { setModalContent, setModalVisible } from "../../../store/modal/actions";
import GiftAvailable from "../GiftAvailable";
import ScanResultUpdate from "../ScanResultUpdate";
import { useDispatch } from "react-redux";
import { SelectInput } from "../../../components/SelectInput";
import Error from "../Error";
import { useTranslation } from "react-i18next";

const CountOfStamps = ({ curStamps, phone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { result } = await addStamps(phone, count);
    if (result) {
      const { maxStamps } = await getChainParams();
      //Если начисленный штамп был недостающим до подарка - спрашиваем, не желает ли гость сразу получить подарок
      dispatch(setModalVisible(true));
      if (Number(curStamps) + Number(count) >= maxStamps) {
        dispatch(setModalContent(<GiftAvailable phone={phone} />));
      } else {
        dispatch(setModalContent(<ScanResultUpdate />));
      }
    } else {
      dispatch(
        setModalContent(
          <Error
            title={t("views.popovers.server_error.title")}
            desc={t("views.popovers.server_error.desc")}
            backUrl={"/"}
            backBtnText={t("views.popovers.server_error.btn_back")}
          />
        )
      );
    }
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <form
          onChange={(e) => setCount(e.target.value)}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col xs={12}>
              <MobilePageHeader>
                {t("views.modals.count_stamps.header")}
              </MobilePageHeader>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <MobilePageDescription>
                {t("views.modals.count_stamps.desc")}
              </MobilePageDescription>
            </Col>
          </Row>

          {(() => {
            const createSelectors = (array) => {
              return array.map((numb, i) => (
                <Col xs={~~(12 / array.length)} key={`${12 - numb}_${i}`}>
                  <SelectInput name={"select"} value={numb} />
                </Col>
              ));
            };

            return (
              <>
                <Row>{createSelectors([1, 2, 3])}</Row>
                <Row>{createSelectors([4, 5, 6])}</Row>
              </>
            );
          })()}

          <Row>
            <Col xs={12}>
              <Button type={"submit"} role={"primary"} display={"block"}>
                {t("views.modals.count_stamps.btn_accrue")}
              </Button>
            </Col>
          </Row>
        </form>
      </MobileModalContent>
    </Container>
  );
};

export default CountOfStamps;
