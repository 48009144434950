import React from "react";
import { Container } from "react-grid-system";
import { Global, Modal, NavBar } from "./components";
import { useRoutes, useOnAuth } from "./hooks";
import { BrowserRouter } from "react-router-dom";
import "./localization/i18n";

export default function App() {
  useOnAuth();
  const routes = useRoutes(false);
  return (
    <>
      <Global />
      <Container>
        <BrowserRouter>
          <Modal />
          <NavBar />
          {routes}
        </BrowserRouter>
      </Container>
    </>
  );
}
