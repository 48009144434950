import React, { useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  MobilePageDescription,
  MobilePageHeader,
  MobileModalContent as CenteredContainer,
  Button,
} from "../../components";
import { useDispatch } from "react-redux";
import { setModalContent, setModalVisible } from "../../store/modal/actions";
import { setExit } from "../../store/navbar/actions";
import ApproveLogout from "../modal-views/ApproveLogout";
import { useTranslation } from "react-i18next";

export default function Main() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setExit(() => {
        dispatch(setModalVisible(true));
        dispatch(setModalContent(<ApproveLogout />));
      })
    );
  }, [dispatch]);
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <MobilePageHeader>{t("views.main.header")}</MobilePageHeader>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <MobilePageDescription>{t("views.main.desc")}</MobilePageDescription>
        </Col>
      </Row>
      <CenteredContainer height={"200px"}>
        <Row>
          <Col xs={12}>
            <Button
              role={"primary"}
              display={"block"}
              tag={"link"}
              href={"/scan"}
            >
              {t("views.main.btn_scan")}
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              role={"secondary"}
              display={"block"}
              tag={"link"}
              href={"/guest/invite"}
            >
              {t("views.main.btn_invite")}
            </Button>
          </Col>
        </Row>
      </CenteredContainer>
    </Container>
  );
}
