import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ru from "./lang/ru.json";
import be from "./lang/be.json";
import en from "./lang/en.json";
export const resources = {
  ru,
  be,
  en,
};

export const fallbackLng = "ru";

export const countryPhoneDictionary = {
  ru: { code: "+7", mask: "(999) 999 99-99" },
  be: { code: "+375", mask: "(99) 999 99-99" },
};

export const IETFDictionary = {
  ru: "Русский",
  en: "English",
  de: "Deutsch",
  be: "Беларускі",
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
