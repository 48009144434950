import SignIn from "../views/SignIn";
import Restore from "../views/Restore";
import Main from "../views/Main";
import GuestInvite from "../views/GuestInvite";
import RegistrationQR from "../views/RegistrationQR";
import Scanner from "../views/Scanner";
import PhoneScan from "../views/PhoneScan";
import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { BounceSwitch } from "../components";
import { useSelector } from "react-redux";

const arrRoutes = {
  forUnauthorized: [
    {
      path: "/",
      component: SignIn,
      default: true,
    },
    {
      path: "/restore",
      component: Restore,
    },
  ],
  forAuthorized: [
    {
      path: "/",
      component: Main,
      default: true,
    },
    {
      path: "/scan",
      component: Scanner,
    },
    {
      path: "/scan/phone",
      component: PhoneScan,
    },
    {
      path: "/guest/invite",
      component: GuestInvite,
    },
    {
      path: "/guest/qr",
      component: RegistrationQR,
    },
  ],
};

export default function useRoutes() {
  const [routes, setRoutes] = useState(null);
  const { jwt } = useSelector(({ app }) => app);
  const isAuthorized = !!jwt;
  useEffect(() => {
    const curRoutes = isAuthorized
      ? arrRoutes.forAuthorized
      : arrRoutes.forUnauthorized;

    const defaultRoute = curRoutes.find((v) => v.default);

    setRoutes(
      <BounceSwitch>
        {curRoutes.map((v, k) => (
          <Route key={k} path={v.path} component={v.component} exact />
        ))}
        {defaultRoute ? <Redirect to={defaultRoute.path} /> : null}
      </BounceSwitch>
    );
  }, [isAuthorized]);

  return routes;
}
