import styled from "styled-components";

const PageHeader = styled.div`
  font-size: 22px;
  line-height: 33px;
  font-weight: 600;
  color: ${({ theme }) => theme.mainText};
  margin-bottom: 20px;
`;

export default PageHeader;
