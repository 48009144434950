import React, { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  Button,
  MobilePageDescription,
  MobilePageHeader,
  PhoneInput,
} from "../../components";
import { isError } from "../../utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addStamps,
  getChainParams,
  getGuestDataByPhone,
} from "../../store/app/actions";
import { setModalContent, setModalVisible } from "../../store/modal/actions";
import GiftAvailable from "../modal-views/GiftAvailable";
import ScanResultUpdate from "../modal-views/ScanResultUpdate";
import GiftScanSelector from "../modal-views/GiftScanSelector";
import { CountOfStamps } from "../modal-views/CountOfStamps";
import Error from "../../views/modal-views/Error";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setGoBack } from "../../store/navbar/actions";
import { useTranslation } from "react-i18next";
import i18n, { countryPhoneDictionary } from "../../localization/i18n";
import {
  getMaskByCode,
  getMaskLengthByLang,
  getPhoneCodeByLang,
} from "../../utils/phone";

const codeItems = Object.entries(countryPhoneDictionary).map(
  ([key, obj], i) => ({ id: i, tag: key, code: obj.code })
);

const PhoneScan = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const multipleStamps = useSelector(({ app }) => app.multipleStamps);
  const validRef = useRef(getMaskLengthByLang(i18n.language));

  useEffect(() => {
    dispatch(
      setGoBack(() => {
        history.push("/scan");
      })
    );
  }, [dispatch, history]);

  const formik = useFormik({
    initialValues: {
      code: getPhoneCodeByLang(i18n.language),
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .length(validRef.current, t("core.validation.phone.invalid"))
        .required(t("core.validation.required")),
    }),
    onSubmit: async (values) => {
      const { result, fields, msg } = await getGuestDataByPhone(
        `${values.code} ${values.phone}`.replace(/\D/g, "")
      );
      if (result) {
        const { curStamps, curGifts, phone } = fields;
        if (!curGifts) {
          // Включена ли опция нескольких штампов у сети. Если включена - показываем модалку с выбором кол-ва штампов
          if (multipleStamps) {
            dispatch(setModalVisible(true));
            dispatch(
              setModalContent(
                <CountOfStamps curStamps={curStamps} phone={phone} />
              )
            );
          } else {
            const { result } = await addStamps(phone);
            if (result) {
              const { maxStamps } = await getChainParams();
              //Если начисленный штамп был недостающим до подарка - спрашиваем, не желает ли гость сразу получить подарок
              dispatch(setModalVisible(true));
              if (curStamps + 1 === maxStamps) {
                dispatch(setModalContent(<GiftAvailable phone={phone} />));
              } else {
                dispatch(setModalContent(<ScanResultUpdate />));
              }
            } else {
              dispatch(
                setModalContent(
                  <Error
                    title={t("views.popovers.server_error.title")}
                    desc={t("views.popovers.server_error.desc")}
                    backUrl={"/"}
                    backBtnText={t("views.popovers.server_error.btn_back")}
                  />
                )
              );
            }
          }
        } else {
          dispatch(setModalVisible(true));
          dispatch(setModalContent(<GiftScanSelector phone={phone} />));
        }
      } else {
        dispatch(setModalVisible(true));
        dispatch(
          setModalContent(
            <Error
              title={t("views.popovers.error_title")}
              desc={msg}
              backUrl={"/scan/phone"}
              backBtnText={t("views.popovers.btn_try_again")}
            />
          )
        );
      }
    },
  });

  useEffect(() => {
    formik.setFieldError("phone", undefined);
    const newValidLength = getMaskByCode(formik.values.code).length;
    validRef.current = newValidLength;
    formik.setFieldValue("phone", formik.values.phone.slice(0, newValidLength));
  }, [formik.values.code]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>{t("views.phone_scan.header")}</MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.phone_scan.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PhoneInput
              name={"phone"}
              code={formik.values.code}
              setCode={(v) => formik.setFieldValue("code", v, false)}
              value={formik.values.phone}
              setValue={(v) => formik.setFieldValue("phone", v, true)}
              codeItems={codeItems}
              placeholder={t("core.input_defs.phone.label")}
              error={isError(formik, "phone") ? formik.errors.phone : ""}
              maskWithoutCode={
                Object.values(countryPhoneDictionary).find(
                  (el) => el.code === formik.values.code
                ).mask
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button display={"block"} disabled={!formik.dirty} type={"submit"}>
              {t("views.phone_scan.btn_submit")}
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default PhoneScan;
