import styled from "styled-components";
import arrow from "./assets/arrow.svg";
import cross from "./assets/cross.svg";
import exit from "./assets/exit.svg";
import PropTypes from "prop-types";

export const rightIcons = {
  "": "",
  cross,
  exit,
};

export const leftIcons = {
  "": "",
  arrow,
};

export const Wrapper = styled.div`
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LeftButton = styled.button`
  border: none;
  background-color: unset;
  background-image: url("${({ icon }) => leftIcons[icon]}");
  background-position: center;
  background-repeat: no-repeat;
  width: 25px;
  height: 20px;
`;

export const RightButton = styled.button`
  border: none;
  background-color: unset;
  background-image: url("${({ icon }) => rightIcons[icon]}");
  background-position: center;
  background-repeat: no-repeat;
  width: 25px;
  height: 20px;
`;

LeftButton.defaultProps = {
  icon: "",
};

RightButton.defaultProps = {
  icon: "",
};

LeftButton.propTypes = {
  icon: PropTypes.oneOf(Object.keys(leftIcons)),
};

RightButton.propTypes = {
  icon: PropTypes.oneOf(Object.keys(rightIcons)),
};
