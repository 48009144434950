import produce from "immer";
import { CLEAR_JWT, SET_JWT, SET_MULTIPLE } from "./types";

const initialState = {
  jwt: localStorage.getItem("apiToken"),
  multipleStamps: false,
};

export default function appReducer(state = initialState, action) {
  const { type, payload } = action;
  return produce(state, (draft) => {
    switch (type) {
      case SET_JWT:
        localStorage.setItem("apiToken", payload);
        draft.jwt = payload;
        return draft;
      case SET_MULTIPLE:
        draft.multipleStamps = payload;
        return draft;
      case CLEAR_JWT:
        localStorage.setItem("apiToken", "");
        draft.jwt = "";
        return draft;
      default:
        return draft;
    }
  });
}
