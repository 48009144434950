import { SET_CONTENT, SET_VISIBLE } from "./types";

export const setModalContent = (component) => (dispatch) => {
  dispatch({
    type: SET_CONTENT,
    payload: component,
  });
};

export const setModalVisible = (isVisible) => (dispatch) => {
  dispatch({
    type: SET_VISIBLE,
    payload: isVisible,
  });
};
