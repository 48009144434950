import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import {
  Button,
  MobileModalContent,
  MobilePageDescription,
  MobilePageHeader,
} from "../../../components";
import QRCode from "qrcode.react";
import { setModalContent, setModalVisible } from "../../../store/modal/actions";
import { getGuestDataById } from "../../../store/app/actions";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function AfterInvite({ clientId }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  useEffect(() => {
    const loadCardUrl = async () => {
      const { cardUrl } = await getGuestDataById(clientId);
      setUrl(cardUrl);
    };

    loadCardUrl();
  }, [clientId]);

  const goToMain = () => {
    dispatch(setModalVisible(false));
    dispatch(setModalContent(null));
    history.push("/");
  };

  return (
    <Container>
      <MobileModalContent height={"100%"}>
        <Row>
          <Col xs={12}>
            <MobilePageHeader>
              {t("views.modals.after_invite.header")}
            </MobilePageHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <MobilePageDescription>
              {t("views.modals.after_invite.desc")}
            </MobilePageDescription>
          </Col>
        </Row>
        <Row>
          <Col s={12}>
            <MobileModalContent height={"350px"}>
              <QRCode size={256} value={url} />
            </MobileModalContent>
          </Col>
        </Row>
        <Row>
          <Col s={12}>
            <Button role={"secondary"} display={"block"} onClick={goToMain}>
              {t("views.modals.after_invite.btn_goto")}
            </Button>
          </Col>
        </Row>
      </MobileModalContent>
    </Container>
  );
}
