import React from "react";
import * as Styled from "./styled";

const SelectInput = ({ name, value }) => {
  return (
    <Styled.Wrapper>
      <input
        type={"radio"}
        id={`${name}_${value}`}
        name={name}
        value={value}
        defaultChecked={value === 1}
      />
      <Styled.SelectInput htmlFor={`${name}_${value}`}>
        <span>{value}</span>
      </Styled.SelectInput>
    </Styled.Wrapper>
  );
};

export default SelectInput;
