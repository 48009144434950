import { getUserLocale } from "get-user-locale";
import { IETFDictionary } from "../localization/i18n";

//de-DE к de
function getFirstPartOfTag(tag) {
  return tag.split("-")[0];
}

const getUserLanguage = () => {
  const langTag = getFirstPartOfTag(getUserLocale());
  return [langTag, IETFDictionary[langTag]];
};

export default getUserLanguage;
